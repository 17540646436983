import React, { useState, useEffect } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import ReCAPTCHA from 'react-google-recaptcha'
import Config from '../../utils/config'

import MemoryStore from '../../utils/memory-store'
import Enums from '../../utils/enums'
import { Card, Button, Input, Row, Col, Form, Spin, Alert, List, message } from 'antd'
import AgiliteHeader from '../../reusables/components/agilite-header'
import PasswordValidationItem from '../../reusables/components/password-validation-item'

const data = [
  {
    property: 'min',
    description: 'Minimum of 8 characters',
    complete: false
  },
  {
    property: 'symbols',
    description: 'Contains a symbol',
    complete: false
  },
  {
    property: 'digits',
    description: 'Contains a number',
    complete: false
  },
  {
    property: 'uppercase',
    description: 'Contains an uppercase letter',
    complete: false
  },
  {
    property: 'password',
    description: 'Passwords must match',
    complete: false
  }
]

const CoreTeamActivate = ({ activateUser, validatePassword, verifyRecaptchaToken, verifyRecaptchaTokenv2 }) => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [alertMessage, setAlertMessage] = useState('')
  const [showAlert, setShowAlert] = useState(false)
  const [lowScore, setLowScore] = useState(null)
  const [activateDisabled, setActivateDisabled] = useState(true)
  const [activateClicked, setActivateClicked] = useState(false)
  const [passwordValid, setPasswordValid] = useState({ value: false })

  const entry = MemoryStore.registerForm
  const reCaptchaEnabled = Config.reCaptcha?.enabled || false

  const onChange = (key, value) => {
    switch (key) {
      case 'inputFirstName':
        entry.firstName = value
        break
      case 'inputLastName':
        entry.lastName = value
        break
      case 'inputPassword':
        entry.password = value

        validatePassword(data, entry.password, (result) => {
          result.map((element) => {
            data.map((obj) => {
              if (obj.property === element) {
                obj.complete = false
              }
              return true
            })
            return true
          })

          // Check if passwords match
          if (entry.password === entry.passwordConfirm && entry.password !== '') {
            data[4].complete = true
          } else {
            data[4].complete = false
          }

          if (result.length === 0 && entry.passwordConfirm === entry.password) {
            setPasswordValid(JSON.parse(JSON.stringify({ value: true })))
          } else {
            setPasswordValid(JSON.parse(JSON.stringify({ value: false })))
          }
        })

        break
      case 'inputPasswordConfirm':
        entry.passwordConfirm = value

        // Check if passwords match
        if (entry.passwordConfirm === entry.password && entry.passwordConfirm !== '') {
          data[4].complete = true
          setPasswordValid(JSON.parse(JSON.stringify({ value: true })))
        } else {
          data[4].complete = false
          setPasswordValid(JSON.parse(JSON.stringify({ value: false })))
        }

        break
      default:
      // Do Nothing
    }
  }

  useEffect(() => {
    if (reCaptchaEnabled && executeRecaptcha) {
      executeRecaptcha('team_activate')
        .then((result) => {
          verifyRecaptchaToken(result, (res, err) => {
            if (err) {
              setLowScore(true)

              if (err.response) {
                message.error(err.response.data.errorMessage)
              } else {
                message.error(Enums.MESSAGES.UNKNOWN_ERROR)
              }
              return
            }

            if (res.data.success) {
              if (res.data.score < 0.5) {
                setLowScore(true)
              } else {
                setLowScore(false)
                setActivateDisabled(false)
              }
            } else {
              setLowScore(true)
            }
          })
        })
        .catch((err) => {
          setLowScore(true)
          console.log(err)
        })
    } else {
      setActivateDisabled(false)
    }
    // eslint-disable-next-line
  }, [executeRecaptcha])

  const verifyRecaptcha = (token) => {
    verifyRecaptchaTokenv2(token, (res, err) => {
      if (err) {
        if (err.response) {
          message.error(err.response.data.errorMessage)
        } else {
          message.error(Enums.MESSAGES.UNKNOWN_ERROR)
        }
        return
      }

      if (res.data.success) {
        setActivateDisabled(false)
      } else {
        message.error('reCaptcha Verification Failed')
      }
    })
  }

  return (
    <Row type='flex' justify='center'>
      <Col xs={23} sm={23} md={16} lg={12} xl={10} xxl={8}>
        <div>
          <div>
            <center>
              <AgiliteHeader header={Enums.VALUES_STRINGS.AGILITE_WELCOME} />
            </center>
          </div>
          <Card style={{ marginTop: 20 }} type='inner' title='Use the below Form to activate your account'>
            <Form style={{ textAlign: 'left' }}>
              <Form.Item>
                First Name
                <Input
                  placeholder='Enter your first name'
                  defaultValue={entry.firstName}
                  required
                  onChange={(e) => {
                    onChange('inputFirstName', e.target.value)
                  }}
                />
              </Form.Item>
              <Form.Item>
                Last Name
                <Input
                  placeholder='Enter your last name'
                  defaultValue={entry.lastName}
                  required
                  onChange={(e) => {
                    onChange('inputLastName', e.target.value)
                  }}
                />
              </Form.Item>
              <Form.Item>
                New Password
                <Input.Password
                  placeholder='Enter a new password'
                  type='password'
                  defaultValue={entry.password}
                  required
                  onChange={(e) => {
                    onChange('inputPassword', e.target.value)
                  }}
                />
              </Form.Item>
              <Form.Item>
                Confirm New Password
                <Input.Password
                  placeholder='Confirm the new password you entered'
                  type='password'
                  required
                  defaultValue={entry.passwordConfirm}
                  onChange={(e) => {
                    onChange('inputPasswordConfirm', e.target.value)
                  }}
                />
              </Form.Item>
              <Row>
                <Col style={{ marginTop: 20 }}>
                  <List
                    style={{ marginBottom: 20 }}
                    dataSource={data}
                    renderItem={(item) => <PasswordValidationItem item={item} />}
                  />
                  <Button
                    disabled={!passwordValid.value || activateDisabled}
                    style={{
                      backgroundColor: '#67AD5B',
                      color: 'white',
                      marginRight: 10
                    }}
                    onClick={() => {
                      setActivateClicked(true)
                      activateUser((successful, message) => {
                        if (successful === false) {
                          setActivateClicked(true)
                          setAlertMessage(message)
                          setShowAlert(true)
                        } else {
                          setActivateClicked(true)
                          setAlertMessage(message)
                          setShowAlert(true)
                        }
                      })
                    }}
                  >
                    Submit
                  </Button>
                  <Button
                    style={{ backgroundColor: '#EB5181', color: 'white' }}
                    onClick={(e) => {
                      // TODO: This block of code is used multiple times and should be centralized in utilities.js
                      e.preventDefault()
                      const port = window.location.port !== '' ? ':' + window.location.port : ''
                      window.open(window.location.protocol + '//' + window.location.hostname + port, '_self')
                    }}
                  >
                    Cancel
                  </Button>
                  {lowScore ? (
                    <div style={{ marginTop: 10 }}>
                      <ReCAPTCHA sitekey={Config.reCaptcha.v2_siteKey} onChange={verifyRecaptcha} />
                    </div>
                  ) : null}
                  {showAlert ? (
                    <div>
                      <Alert
                        style={{ marginTop: 10, marginBottom: 10 }}
                        message={alertMessage}
                        closable={false}
                        type='error'
                      />
                    </div>
                  ) : null}
                  {activateClicked ? (
                    <div className='spin'>
                      <Spin size='large' />
                    </div>
                  ) : null}
                </Col>
              </Row>
            </Form>
          </Card>
          {reCaptchaEnabled ? (
            <Card style={{ textAlign: 'center', marginTop: 10 }}>
              This site is protected by reCAPTCHA and the Google
              <a href='https://policies.google.com/privacy' rel='noopener noreferrer' target='_blank'>
                {' '}
                Privacy Policy
              </a>{' '}
              and
              <a href='https://policies.google.com/terms' rel='noopener noreferrer' target='_blank'>
                {' '}
                Terms of Service
              </a>{' '}
              apply.
            </Card>
          ) : null}
        </div>
      </Col>
    </Row>
  )
}

export default CoreTeamActivate
